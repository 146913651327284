import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";

const today = new Date()
const year = today.getFullYear()
const YearOfBirth = [...Array(100).keys()].map(i => year + 543 - i)

function DialogYearOfBirth(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: "150px" } }}
    >
      {/* <DialogTitle>Set backup account</DialogTitle> */}
      <List sx={{ pt: 0 }}>
        {YearOfBirth.map((e) => (
          <ListItem disableGutters>
            <ListItemButton
              onClick={() => handleListItemClick(e)}
              key={e}
              sx={{
                backgroundColor:
                  e === selectedValue ? "rgb(0,0,0,.1)" : "",
              }}
            >
              <ListItemText primary={e} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

DialogYearOfBirth.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
export default DialogYearOfBirth;
