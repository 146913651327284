import { lazy } from "react";

import MyCouponLayout from "layout/MyCouponLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Guard from "layout/Guard";
const MyCoupon = Loadable(lazy(() => import("pages/MyCoupon/myCoupon")));
const MyCoupoInfo = Loadable(lazy(() => import("pages/MyCoupon/myCoupoInfo")));
const ViewCode = Loadable(
  lazy(() => import("pages/MyCoupon/ViewCode/ViewCode"))
);
const MyCoupoUsed = Loadable(lazy(() => import("pages/MyCoupon/myCoupoUsed")));

const MainRoutes = {
  path: "/",
  element: <Guard />,
  children: [
    {
      path: "/MyCoupon",
      element: (
        <AuthGuard>
          <MyCouponLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <MyCoupon />,
        },
        {
          path: "info/:id",
          element: <MyCoupoInfo />,
        },
        {
          path: "used/:id",
          element: <MyCoupoUsed />,
        },
        {
          path: "viewcode/:id",
          element: <ViewCode />,
        },
      ],
    },
  ],
};

export default MainRoutes;
