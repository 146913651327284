import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import App from "../index";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
const DrawerUC = ({ isDrawer, setisDrawer }) => {
  let navigate = useNavigate();
  const [member, setmember] = useState({});
  const getmember = async () => {
    if (isDrawer) {
      let member = await App.service.getHttp("frontend/home/member");
      if (member.status) {
        setmember(member.data.data);
      }
    }
  };
  useEffect(() => {
    getmember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawer]);
  return App.service.isNullOrEmpty(localStorage.getItem("token")) ? null : (
    <Drawer
      anchor={"right"}
      open={isDrawer}
      onClose={() => setisDrawer(false)}
      sx={{
        width: "100%",
        "& .MuiPaper-root": {
          width: "75%",
          backgroundColor: "transparent",
        },
      }}
    >
      {/* {list(anchor)} */}
      <Box
        style={{
          width: "100%",
          height: "100%",
          background: "#1E1E1E",
          borderRadius: "8px 0px 0px 8px",
          padding: 20,
          position: "relative",
        }}
      >
        <Box onClick={() => setisDrawer(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill="#F3F3F3"
            />
          </svg>
        </Box>
        <Box mt={2} sx={{ display: "flex" }}>
          <Box>
            <Avatar
              alt="Remy Sharp"
              src={member.image}
              sx={{ width: 80, height: 80 }}
            />
          </Box>
          <Box pl={2} sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <App.Label text={member.FirstName} color={"#FFF"} size={28} />
              <App.Label text={member.LastName} color={"#FFF"} size={28} />
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <App.Label text={"edit"} color={"#B79C67"} />
                <Box pl={0.5} sx={{ display: "flex", alignItems: "center" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.12098 1.90404C8.39623 1.60592 8.76767 1.43538 9.15684 1.42844C9.546 1.4215 9.92242 1.57871 10.2066 1.86686C10.4907 2.15501 10.6604 2.55154 10.6797 2.97267C10.699 3.39381 10.5664 3.80658 10.31 4.12379L10.2425 4.20179L9.88898 4.58475L10.2425 4.96825C10.4193 5.15993 10.5234 5.41644 10.5342 5.68718C10.5451 5.95792 10.4618 6.22323 10.301 6.43075L10.2425 6.50009L8.12148 8.79838C8.03171 8.89656 7.91069 8.95385 7.7832 8.95854C7.65572 8.96322 7.53139 8.91495 7.43569 8.82358C7.33998 8.73222 7.28013 8.60469 7.26838 8.46708C7.25663 8.32948 7.29387 8.19221 7.37248 8.08338L7.41398 8.03192L9.53548 5.73417L9.18198 5.35121L4.24298 10.7018C4.18171 10.7681 4.10685 10.8179 4.02448 10.847L3.96198 10.8648L2.21648 11.2429C2.13979 11.2596 2.06056 11.2568 1.98504 11.2347C1.90952 11.2126 1.83978 11.1718 1.78131 11.1155C1.72284 11.0592 1.67725 10.9889 1.64812 10.9103C1.619 10.8316 1.60714 10.7467 1.61348 10.6623L1.62198 10.5989L1.97098 8.70846C1.98794 8.61632 2.02477 8.52982 2.07848 8.45604L2.12148 8.4035L8.12148 1.9035L8.12098 1.90404ZM3.17198 8.79784L2.92548 9.06542L2.74848 10.0225L3.63248 9.83134L3.87898 9.56429L3.17198 8.79784ZM7.76848 3.81884L3.87898 8.03192L4.58648 8.79838L8.47548 4.58475L7.76848 3.81884ZM9.53498 2.6705C9.44888 2.57724 9.33433 2.52122 9.21282 2.51294C9.09131 2.50466 8.97118 2.5447 8.87498 2.62554L8.82798 2.6705L8.47448 3.05346L9.18148 3.81938L9.53498 3.43642L9.57648 3.3855C9.6511 3.28128 9.68806 3.15115 9.68042 3.01951C9.67278 2.88787 9.62107 2.76377 9.53498 2.6705Z"
                      fill="#00AFA2"
                    />
                  </svg>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          mt={2}
          mb={2}
          style={{
            border: "1px solid #FFF",
          }}
        ></Box>
        <Box
          mb={1}
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            navigate("/profile/termsAndConditions");
          }}
        >
          <App.Label text="Terms and Condition" size={16} color="#FFF" />
          <Box
            pl={4}
            style={{
              transform: "rotate(0.5turn) scale(.8)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="15"
              viewBox="0 0 12 20"
              fill="none"
            >
              <path
                d="M10.88 0.87993C10.7639 0.763522 10.626 0.671164 10.4741 0.608148C10.3223 0.545132 10.1595 0.512695 9.99504 0.512695C9.83062 0.512695 9.66782 0.545132 9.51595 0.608148C9.36409 0.671164 9.22615 0.763522 9.11004 0.87993L0.700037 9.28993C0.607333 9.38244 0.533785 9.49233 0.483604 9.61331C0.433422 9.73428 0.407593 9.86396 0.407593 9.99493C0.407593 10.1259 0.433422 10.2556 0.483604 10.3766C0.533785 10.4975 0.607333 10.6074 0.700037 10.6999L9.11004 19.1099C9.60004 19.5999 10.39 19.5999 10.88 19.1099C11.37 18.6199 11.37 17.8299 10.88 17.3399L3.54004 9.99993L10.89 2.64993C11.37 2.15993 11.37 1.36993 10.88 0.87993Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
        <Box
          mb={1}
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            navigate("/profile/privacyPolicy");
          }}
        >
          <App.Label text="Privacy Policy" size={16} color="#FFF" />
          <Box
            pl={4}
            style={{
              transform: "rotate(0.5turn) scale(.8)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="15"
              viewBox="0 0 12 20"
              fill="none"
            >
              <path
                d="M10.88 0.87993C10.7639 0.763522 10.626 0.671164 10.4741 0.608148C10.3223 0.545132 10.1595 0.512695 9.99504 0.512695C9.83062 0.512695 9.66782 0.545132 9.51595 0.608148C9.36409 0.671164 9.22615 0.763522 9.11004 0.87993L0.700037 9.28993C0.607333 9.38244 0.533785 9.49233 0.483604 9.61331C0.433422 9.73428 0.407593 9.86396 0.407593 9.99493C0.407593 10.1259 0.433422 10.2556 0.483604 10.3766C0.533785 10.4975 0.607333 10.6074 0.700037 10.6999L9.11004 19.1099C9.60004 19.5999 10.39 19.5999 10.88 19.1099C11.37 18.6199 11.37 17.8299 10.88 17.3399L3.54004 9.99993L10.89 2.64993C11.37 2.15993 11.37 1.36993 10.88 0.87993Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
        <Box
          mb={1}
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {}}
        >
          <App.Label text="Application Manual" size={16} color="#FFF" />
        </Box>
        <Box style={{}} sx={{ position: "absolute", bottom: 0, right: 0 }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="209"
            height="286"
            viewBox="0 0 209 286"
            fill="none"
          >
            <path
              d="M127.04 77.7061V191.333L183.415 134.502L175.715 126.739L127.04 77.7061Z"
              fill="#002856"
              fillOpacity="0.5"
            />
            <path
              d="M187.606 139.051L131.267 195.811H243.945L187.606 139.051Z"
              fill="#00AFA2"
              fillOpacity="0.5"
            />
            <path
              d="M121.31 73.626H4.04712L62.3919 132.407C62.3919 132.407 62.6068 132.516 62.6784 132.588L121.274 191.658V73.626H121.31Z"
              fill="#002856"
              fillOpacity="0.5"
            />
            <path
              d="M248.816 73.626H131.589L189.934 132.407C189.934 132.407 190.149 132.516 190.22 132.588L248.816 191.658V73.626Z"
              fill="#002856"
              fillOpacity="0.5"
            />
            <path
              d="M4.19043 195.811H117.083L60.6369 138.907L4.19043 195.811Z"
              fill="#00AFA2"
              fillOpacity="0.5"
            />
            <path
              d="M0 77.7061V191.694L56.5181 134.718L0 77.7061Z"
              fill="#002856"
              fillOpacity="0.5"
            />
            <path
              d="M127.04 205.632V319.223L183.415 262.427L175.715 254.665L127.04 205.632Z"
              fill="#00AFA2"
              fillOpacity="0.5"
            />
            <path
              d="M187.606 266.941L131.267 323.7H243.945L187.606 266.941Z"
              fill="#002856"
              fillOpacity="0.5"
            />
            <path
              d="M121.31 201.516H4.04712L62.3919 260.297C62.3919 260.297 62.6068 260.405 62.6784 260.478L121.274 319.548V201.479L121.31 201.516Z"
              fill="#00AFA2"
              fillOpacity="0.5"
            />
            <path
              d="M4.19043 323.7H117.083L60.6369 266.833L4.19043 323.7Z"
              fill="#002856"
              fillOpacity="0.5"
            />
            <path
              d="M248.422 201.516H131.159L185.958 256.795L189.79 260.658L248.422 319.765V201.516Z"
              fill="#002856"
              fillOpacity="0.5"
            />
            <path
              d="M0 205.632V319.584L56.5181 262.608L0 205.632Z"
              fill="#002856"
              fillOpacity="0.5"
            />
            <path
              d="M124.318 67.8852C123.996 67.8852 123.674 67.8491 123.351 67.7408C121.31 67.0548 73.0294 50.4458 63.6814 28.3846C58.4164 15.964 62.7859 8.77879 65.8661 5.60141C71.2386 0.00489865 80.7299 -1.6199 88.0006 1.73801C107.879 10.9091 120.414 30.6594 127.399 63.9496C127.649 65.105 127.255 66.2604 126.431 67.0548C125.858 67.5964 125.106 67.8852 124.318 67.8852ZM79.7987 6.46797C76.2171 6.46797 72.5996 7.76781 70.3074 10.1508C66.9048 13.6532 66.6183 19.0331 69.4478 25.7128C75.5007 40.011 104.297 53.5509 119.985 59.5807C111.174 23.3658 96.2025 12.6061 85.4577 7.65949C83.7027 6.86514 81.7686 6.46797 79.7987 6.46797Z"
              fill="#002856"
              fillOpacity="0.5"
            />
            <path
              d="M125.214 67.885C124.462 67.885 123.71 67.5961 123.101 67.0545C122.277 66.2602 121.883 65.1048 122.134 63.9494C129.118 30.6591 141.654 10.9089 161.532 1.7378C168.802 -1.62011 178.294 0.0407886 183.666 5.56509C186.746 8.74247 191.116 15.9277 185.851 28.3483C176.503 50.4094 128.222 67.0184 126.181 67.7045C125.859 67.8128 125.536 67.8489 125.214 67.8489V67.885ZM169.733 6.46775C167.764 6.46775 165.83 6.86493 164.075 7.65927C153.365 12.6059 138.394 23.3656 129.548 59.5444C142.227 54.6339 173.53 41.2023 180.084 25.7125C182.914 19.0328 182.627 13.653 179.225 10.1506C176.933 7.76759 173.315 6.46775 169.733 6.46775Z"
              fill="#002856"
              fillOpacity="0.5"
            />
          </svg>
        </Box>
      </Box>
    </Drawer>
  );
};
export default DrawerUC;
