import App from "components";
import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setnotification, setUnitID } from "../../store/reducers/actions";
const Guard = () => {
  const home = useSelector((state) => state.home);
  const dispatch = useDispatch();
  // const getNotification = async () => {
  //   console.log("Guard : " + home.UnitNo);
  //   let _notification = await App.service.postHttp(
  //     "frontend/home/notification",
  //     { Unit: home.Unit, UnitNo: home.UnitNo }
  //   );
  //   dispatch(setnotification(_notification?.data?.data || []));
  // };
  // const getcheckhome = async () => {
  //   let Unit = await App.service.getHttp("frontend/home/Unit");
  //   let localhome = localStorage.getItem("home");
  //   if (App.service.isNullOrEmpty(localhome)) {
  //     dispatch(
  //       setUnitID({
  //         Unit: Unit.data.data[0].Unit,
  //         UnitNo: Unit.data.data[0].UnitNo,
  //         UnitID: Unit.data.data[0].UnitID,
  //       })
  //     );
  //   } else {
  //     localhome = JSON.parse(localhome);
  //     dispatch(
  //       setUnitID({
  //         Unit: localhome.Unit,
  //         UnitNo: localhome.UnitNo,
  //         UnitID: localhome.UnitID,
  //       })
  //     );
  //   }
  //   // console.log("home", home.UnitNo);
  // };
  // useEffect(() => {
  //   getNotification();
  // }, [home]);
  useEffect(() => {
    // getcheckhome();
  }, []);
  return <Outlet />;
};
export default Guard;
