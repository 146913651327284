import React from "react";
import App from "components";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import DrawerUC from "../DrawerUC";
const SecondaryMenu = ({
  name,
  isBorder,
  isProfile,
  isGoBack,
  isClose,
  Close,
  GoBack,
}) => {
  let navigate = useNavigate();
  const [isDrawer, setisDrawer] = React.useState(false);
  return (
    <Box
      style={{
        width: "100%",
        height: "70px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#1E1E1E",
        position: "absolute",
        zIndex: "99",
        borderRadius: isBorder === true ? "0px 0px 16px 16px" : "",
      }}
      pl={2}
      pr={2}
    >
      {isGoBack !== false ? (
        <Box
          sx={{
            width: "50px",
          }}
          onClick={() => {
            App.service.isNullOrEmpty(localStorage.getItem("token"))
              ? navigate("/")
              : GoBack == undefined
              ? navigate(-1)
              : GoBack();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="none"
          >
            <path
              d="M10.88 0.87993C10.7639 0.763522 10.626 0.671164 10.4741 0.608148C10.3222 0.545132 10.1594 0.512695 9.99501 0.512695C9.83059 0.512695 9.66779 0.545132 9.51592 0.608148C9.36406 0.671164 9.22612 0.763522 9.11001 0.87993L0.700006 9.28993C0.607302 9.38244 0.533755 9.49233 0.483573 9.61331C0.433392 9.73428 0.407562 9.86396 0.407562 9.99493C0.407562 10.1259 0.433392 10.2556 0.483573 10.3766C0.533755 10.4975 0.607302 10.6074 0.700006 10.6999L9.11001 19.1099C9.60001 19.5999 10.39 19.5999 10.88 19.1099C11.37 18.6199 11.37 17.8299 10.88 17.3399L3.54001 9.99993L10.89 2.64993C11.37 2.15993 11.37 1.36993 10.88 0.87993Z"
              fill="white"
            />
          </svg>
        </Box>
      ) : (
        <Box
          sx={{
            width: "50px",
          }}
        />
      )}
      <Box sx={{ width: "calc(100% - 100px)", textAlign: "center" }}>
        <App.Label
          text={name}
          size={28}
          color={"#FFF"}
          fontWeight="500"
          numberOfLine={1}
        />
      </Box>

      <Box
        style={{
          width: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* {isProfile != false ? (
          <Box
            style={{ marginLeft: 10, display: "flex" }}
            onClick={() =>
              App.service.isNullOrEmpty(localStorage.getItem("token"))
                ? navigate("/")
                : setisDrawer(true)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
            >
              <path
                d="M11.7591 21.5498H1.25909V19.2165H11.7591V21.5498ZM22.2591 15.7165H1.25909V13.3831H22.2591V15.7165ZM22.2591 9.88314H11.7591V7.5498H22.2591V9.88314Z"
                fill="white"
              />
            </svg>
          </Box>
        ) : null} */}
        {isClose == true ? (
          <Box
            style={{ marginLeft: 10, display: "flex" }}
            onClick={() => {
              if (Close == undefined) {
                navigate(-1);
              } else {
                Close();
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M20.5002 2.48361L18.0327 0.0161133L10.5165 7.53236L3.00021 0.0161133L0.532715 2.48361L8.04896 9.99986L0.532715 17.5161L3.00021 19.9836L10.5165 12.4674L18.0327 19.9836L20.5002 17.5161L12.984 9.99986L20.5002 2.48361Z"
                fill="white"
              />
            </svg>
          </Box>
        ) : null}
      </Box>
      <DrawerUC isDrawer={isDrawer} setisDrawer={setisDrawer} />
    </Box>
  );
};

export default SecondaryMenu;
