import liff from "@line/liff";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import service from "undefined-service-web";
import App from "components";
import { useDispatch } from "react-redux";
import { setAuthUser } from "../../store/reducers/actions.js";
import { Box } from "@mui/material";
const GuestGuard = ({ children }) => {
  const dispatch = useDispatch();

  const initLine = async () => {
    await liff.init({ liffId: '1661419705-0goMwPBX' });
    // await liff.init({ liffId: '2000509703-8Y1a29ao' });
    if (liff.isLoggedIn()) {
      liff
        .getProfile()
        .then(async (profile) => {
          const LineID = profile.userId;
          localStorage.setItem("CHAODOI-LineID", LineID);
          const linelogin = await App.service.postHttp("auth/linelogin", {
            LineID: LineID,
          });
          if (linelogin?.data.status) {
            localStorage.setItem("token", linelogin.data.access_token);
            dispatch(setAuthUser(linelogin.data.access_token));
          }
        })
        .catch((err) => console.error(err));
    } else {
      liff.login();
    }
  };

  const { authUser } = useSelector(({ auth }) => auth);
  const navigate = useNavigate();
  // console.log("GuestGuard", liff.isInClient());
  useEffect(() => {
    if (liff.isInClient()) {
      const LineID = localStorage.getItem("CHAODOI-LineID");
      // initLine();
      if (service.isNullOrEmpty(LineID)) {
        console.log("initLine");
        initLine();
      } else {
        let token = localStorage.getItem("token");
        if (!service.isNullOrEmpty(token) && !service.isNullOrEmpty(authUser)) {
          dispatch(setAuthUser(token));
          navigate("MyCoupon", { replace: true });
        } else if (!service.isNullOrEmpty(token)) {
          dispatch(setAuthUser(token));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, navigate]);
  // return children;
  return liff.isInClient() ? (
    children
  ) : (
    <Box
      sx={{}}
      width="100%"
      display="flex"
      justifyContent={"center"}
      alignItems="center"
      height={"100vh"}
    >
      <Box>
        <Box display="flex" justifyContent={"center"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="138"
            height="56"
            viewBox="0 0 138 56"
            fill="none"
          >
            <g clip-path="url(#clip0_700_96)">
              <path
                d="M16.3063 53.8442C14.6977 55.2864 12.6374 56.0075 10.1175 56.0075C7.2648 56.0075 4.95887 55.0487 3.19971 53.139C1.46432 51.1976 0.600586 48.7649 0.600586 45.825C0.600586 42.8851 1.48017 40.603 3.23933 38.6378C4.99849 36.6409 7.28857 35.6504 10.1096 35.6504C12.3204 35.6504 14.2777 36.2843 15.9655 37.5601C17.8515 39.0261 18.7865 40.8566 18.7865 43.0436H13.8022C13.7151 42.0769 13.3268 41.3241 12.6215 40.8011C11.9163 40.2781 11.0367 40.0087 9.98279 40.0087C8.48512 40.0087 7.36781 40.5634 6.63879 41.6727C5.90185 42.7821 5.53733 44.1689 5.53733 45.825C5.53733 47.4812 5.886 48.7886 6.5754 49.8109C7.37574 51.0312 8.50889 51.6413 9.98279 51.6413C12.2808 51.6413 13.5566 50.4369 13.8022 48.0279H18.7865C18.7865 50.4686 17.9545 52.4021 16.2904 53.8442H16.3063Z"
                fill="white"
              />
              <path
                d="M32.7567 55.6272V47.5684H26.2748V55.6272H21.3301V36.0625H26.2748V43.2101H32.7567V36.11H37.7014V55.6352H32.7567V55.6272Z"
                fill="white"
              />
              <path
                d="M53.193 55.6272L52.0281 52.1406H45.3798L44.1753 55.6272H39.1514L46.2118 36.0625H51.2357L58.3357 55.6272H53.185H53.193ZM48.7475 41.4589L46.5446 48.3132H50.8236L48.7475 41.4589Z"
                fill="white"
              />
              <path
                d="M75.3727 53.0518C73.6532 55.017 71.3393 55.9996 68.4311 55.9996C65.523 55.9996 63.2963 55.0566 61.5767 53.1786C59.8889 51.2927 59.041 48.8441 59.041 45.825C59.041 42.8059 59.8572 40.5237 61.4896 38.6378C63.2329 36.6409 65.5468 35.6504 68.4232 35.6504C69.8337 35.6504 71.1174 35.8881 72.2664 36.3556C73.4154 36.8232 74.4377 37.576 75.3172 38.5982C76.9496 40.4841 77.7658 42.8931 77.7658 45.825C77.7658 48.7569 76.9655 51.1976 75.3569 53.0518H75.3727ZM71.8385 41.8392C71.0857 40.6188 69.9447 40.0087 68.4153 40.0087C66.9969 40.0087 65.8875 40.5792 65.0792 41.7124C64.3581 42.8217 63.9936 44.1926 63.9936 45.825C63.9936 47.4574 64.3264 48.7886 64.9921 49.8109C65.7449 51.0312 66.8859 51.6413 68.4153 51.6413C69.8654 51.6413 71.0065 51.047 71.8385 49.8584C72.5042 48.86 72.837 47.5208 72.837 45.8329C72.837 44.256 72.5042 42.9248 71.8385 41.8471V41.8392Z"
                fill="white"
              />
              <path
                d="M107.078 53.0916C106.277 53.9237 105.295 54.5497 104.138 54.9776C102.973 55.4055 101.689 55.6194 100.279 55.6194H92.1167V36.0547H100.279C101.689 36.0547 102.965 36.2766 104.114 36.7203C105.263 37.1641 106.238 37.798 107.054 38.63C107.87 39.4621 108.488 40.4764 108.916 41.6808C109.344 42.8853 109.558 44.2324 109.558 45.7301C109.558 48.8601 108.726 51.3087 107.07 53.0837L107.078 53.0916ZM99.3911 40.4209H97.0614V51.2611H99.3911C101.222 51.2611 102.545 50.8412 103.377 50.0091C104.209 49.1771 104.621 47.7587 104.621 45.7539C104.621 42.1959 102.878 40.413 99.3911 40.413V40.4209Z"
                fill="white"
              />
              <path
                d="M127.878 53.0518C126.159 55.017 123.853 55.9996 120.945 55.9996C118.037 55.9996 115.81 55.0566 114.09 53.1786C112.403 51.2927 111.555 48.8441 111.555 45.825C111.555 42.8059 112.371 40.5237 114.003 38.6378C115.747 36.6409 118.06 35.6504 120.937 35.6504C122.347 35.6504 123.631 35.8881 124.78 36.3556C125.929 36.8232 126.943 37.576 127.831 38.5982C129.463 40.4841 130.279 42.8931 130.279 45.825C130.279 48.7569 129.479 51.1976 127.871 53.0518H127.878ZM124.344 41.8392C123.591 40.6188 122.45 40.0087 120.921 40.0087C119.503 40.0087 118.393 40.5792 117.585 41.7124C116.864 42.8217 116.499 44.1926 116.499 45.825C116.499 47.4574 116.832 48.7886 117.506 49.8109C118.259 51.0312 119.4 51.6413 120.929 51.6413C122.379 51.6413 123.52 51.047 124.352 49.8584C125.018 48.86 125.351 47.5208 125.351 45.8329C125.351 44.256 125.018 42.9248 124.352 41.8471L124.344 41.8392Z"
                fill="white"
              />
              <path
                d="M132.989 55.6272V36.0625H137.934V55.6272H132.989Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M133.021 27.0923C132.142 26.5455 130.248 25.4995 125.073 23.4155C118.1 20.6103 114.273 20.3567 112.736 20.1824C111.206 20.016 111.547 19.5009 109.249 18.138C106.951 16.775 106.436 18.138 104.74 18.4787C103.036 18.8195 103.298 17.7972 99.2962 16.1807C95.2945 14.5642 91.8079 8.52598 89.2563 7.8445C86.7047 7.16303 88.8284 8.69239 85.3417 10.6497C81.8551 12.6069 81.2608 16.1807 79.3907 15.4992C77.5206 14.8178 73.0118 11.6719 70.9673 9.7146C68.9229 7.75734 65.777 5.11859 61.3553 1.37839C56.9336 -2.36181 58.1223 2.31344 54.3821 5.9744C50.6339 9.62744 51.8305 9.62744 50.4675 11.1647C49.1046 12.6941 50.0396 14.8257 49.3582 14.3978C48.6767 13.9699 47.5752 13.8906 45.7844 13.5499C43.9935 13.2092 39.8254 5.808 38.2089 4.44504C36.5923 3.08209 36.3388 4.1043 35.1501 5.64159C33.9615 7.17095 32.2578 8.70031 32.2578 8.70031C28.0897 8.61315 26.2196 13.3756 22.8122 14.057C19.4048 14.7385 19.492 16.783 17.4476 18.0587C15.4031 19.3345 15.5775 18.3123 13.533 18.8274C11.7897 19.2632 6.26659 25.3331 4.69761 27.1002H0.410645V28.0828H137.514V27.1002H133.013L133.021 27.0923Z"
                fill="#006838"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.4395 26.6884C17.4395 26.6884 20.831 22.6947 22.8913 22.449C24.9516 22.2034 21.8136 25.8643 25.0863 24.7708C28.3589 23.6852 30.0626 20.8325 34.7854 21.197C39.5082 21.5615 37.3846 22.5124 35.8076 23.2414C34.2307 23.9704 38.4385 24.0418 39.8886 23.9229C41.3387 23.804 37.3053 26.6409 29.6823 26.6409L17.4474 26.6805L17.4395 26.6884Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.7335 10.0949C32.7335 10.0949 35.2771 10.3406 36.0062 9.36592C36.7352 8.39917 35.5228 5.85552 37.0997 7.55129C38.6766 9.24706 35.0394 1.74288 40.4912 9.36592C45.9431 16.9969 44.8495 14.5721 46.1887 15.0555C47.52 15.5388 48.3678 15.9033 48.6135 16.989C48.8512 18.0825 44.1284 13.8431 44.6118 16.8701C45.0952 19.8971 41.2203 13.3597 41.2203 14.0887C41.2203 14.8177 38.5577 15.7845 37.4642 16.6324C36.3707 17.4803 35.2851 13.9699 37.4642 13.2408C39.6433 12.5118 35.6496 12.6386 36.133 11.2994C36.6163 9.96815 34.8017 14.9366 30.5623 15.2932C26.3228 15.6577 31.529 14.5642 32.7414 12.6307C33.9538 10.6893 33.2248 11.0538 32.7414 10.087L32.7335 10.0949Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M62.1714 3.1612C62.1714 3.1612 59.5644 0.665094 59.9051 2.59859C60.2459 4.52416 60.127 2.59859 59.6753 5.99805C59.2237 9.39751 58.043 8.35152 58.4312 11.2201C58.7165 13.3517 55.2774 13.5339 55.3488 15.0474C55.428 16.7274 60.1508 12.5434 60.1508 16.5134C60.1508 20.4834 58.3599 21.6166 59.8338 21.2758C61.3077 20.9351 59.9131 12.5751 63.5423 13.5973C67.1716 14.6195 59.5089 22.4486 63.3204 23.3361C73.915 25.7926 59.9844 22.5516 64.6279 19.7306C67.6708 17.8843 64.4457 13.8271 65.5788 12.694C66.712 11.5608 66.3712 12.8128 68.1859 14.8493C70.0005 16.8938 70.9118 16.7749 72.7264 16.7749C74.5411 16.7749 74.2637 15.5229 75.8485 16.0934C77.4334 16.664 78.0594 18.0982 80.3257 18.5499C82.592 19.0016 77.7186 16.0934 74.8818 14.8493C72.0449 13.5973 70.9118 11.1012 71.4823 13.9381C72.0449 16.7749 69.7786 11.4419 67.7342 9.73825C65.6898 8.03456 66.0305 6.67953 64.4457 6.21993C62.8608 5.76825 61.9496 5.87919 61.7277 4.97584C61.5058 4.06456 62.1794 3.1612 62.1794 3.1612H62.1714Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M49.8257 14.9287C49.8257 14.9287 53.55 19.1523 54.0017 21.4265C54.4534 23.6928 54.5723 25.6422 55.9352 25.761C57.3695 25.8799 53.035 22.2269 56.2918 22.7499C59.1286 23.2015 58.1936 25.6263 60.4678 26.3078C65.2778 27.75 72.8691 26.8704 72.5522 26.9021C72.5522 26.9021 64.6439 26.5931 62.2587 25.7927C59.8735 25.0003 60.0161 23.1302 57.7498 22.4487C55.4835 21.7673 56.0541 22.5597 55.0319 21.5375C54.0096 20.5152 52.5595 16.6324 49.8336 14.9287H49.8257Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M89.9614 8.83517C89.795 8.13784 89.169 8.9778 88.9392 11.3313C88.3766 17.2268 90.5319 12.2426 90.9836 13.3757C91.4353 14.5089 93.139 16.6642 93.139 17.2348C93.139 17.8053 90.5319 19.1603 92.9171 20.5233C95.2944 21.8863 93.2579 18.9305 95.8649 19.7309C98.4799 20.5233 100.667 21.7595 103.583 21.3157C111.071 20.1826 105.738 21.8863 101.539 19.2713C97.3467 16.6642 98.361 17.7974 96.7762 15.8639C95.1913 13.9383 90.6508 11.6641 89.9693 8.83517H89.9614Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M80.7138 26.7278C80.7138 26.7278 58.4232 20.3489 71.189 21.1968C78.0355 21.6564 80.7138 25.2856 89.2244 25.7928C97.7349 26.2999 103.004 25.9671 105.39 25.6184C105.39 25.6184 97.9251 24.5566 100.809 24.2238C103.702 23.883 111.055 24.8181 111.055 24.8181C111.055 24.8181 107.505 24.7547 107.505 25.5709C107.505 26.3158 117.3 26.1335 117.3 26.1335C117.3 26.1335 110.152 23.4076 116.793 22.7261C123.425 22.0446 112.894 24.1445 122.577 24.937C126.595 25.2698 131.595 26.9655 131.595 26.9655L80.7218 26.7199L80.7138 26.7278Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_700_96">
                <rect
                  width="137.524"
                  height="56"
                  fill="white"
                  transform="translate(0.410645)"
                />
              </clipPath>
            </defs>
          </svg>
        </Box>
        <Box pt={2} color={"#FFF"}>
          is not running in a LIFF browser.
        </Box>
      </Box>
    </Box>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
